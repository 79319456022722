import React from 'react'
import Helmet from 'react-helmet'

import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Paper, Typography } from '@material-ui/core'

import owlBeBackNoText from '../images/owl-be-back-no-text.png'
import Layout from '../components/Layout'

const useStyles = makeStyles(
	theme => ({
		root: {
			paddingBottom: theme.spacing(8),
			paddingTop: theme.spacing(8),
		},
		paper: {
			margin: theme.spacing(3),
			padding: theme.spacing(3),
		},
		img: {
			width: '100%',
		},
	}),
	{ name: 'NotFoundPage' }
)

const NotFoundPage = () => {
	const classes = useStyles()

	return (
		<Layout>
			<Container className={classes.root}>
				<Helmet>
					<title>Page not found</title>
				</Helmet>
				<Paper className={classes.paper} elevation={1}>
					<Grid
						container
						direction={'column'}
						alignItems={'center'}
						justify={'center'}
						spacing={2}
					>
						<Grid item>
							<img
								src={owlBeBackNoText}
								className={classes.img}
								alt={'Not Found'}
							/>
						</Grid>
						<Grid item>
							<Typography
								align={'center'}
								gutterBottom
								variant={'h3'}
							>
								Page not found
							</Typography>
							<Typography align={'center'} component={'p'}>
								Sorry, no can do, Kimosabe.
							</Typography>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</Layout>
	)
}
export default NotFoundPage
